<template>
    <header class="head">
    <nav class="navbar navbar-expand-md nav container-fluid" style="padding: 0;">
         <router-link to="/main"  class="navbar-brand">
            <img src="../img/Logo_hedder.svg" alt="1">
         </router-link>
        <button class="navbar-toggler" type="button" id="navbarToggleBtn" style="fill:#EAECEC;">
            <img src="../img/navbar_togler.svg" alt="1">
        </button>
        <div class="collapse navbar-collapse " id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item nav_list__item" style="margin-right: 70px;">
                    <router-link to="/main"  class="  nav_list_item__link" style=" font-family: Gilroy;font-size: 16px;font-style: normal;font-weight: 600;line-height: normal; background-color:#1E1C24; width: auto; height: 15px;">Главная</router-link>
                </li>
                <li class="nav-item nav_list__item" style="margin-right: 70px;">
                    <router-link to="/indev" class=" nav_list_item__link" style=" font-family: Gilroy;font-size: 16px;font-style: normal;font-weight: 600;line-height: normal; background-color:#1E1C24; width: auto; height: 15px;">Турниры</router-link>
                </li>
                <li class="nav-item nav_list__item" style="margin-right: 70px;">
                        <router-link to="/raiting" class="  nav_list_item__link" style=" font-family: Gilroy;font-size: 16px;font-style: normal;font-weight: 600;line-height: normal; background-color:#1E1C24; width: auto; height: 15px;">Рейтинг</router-link>
                </li>
               
                <li class="nav-item nav_list__item" style="margin-right: 70px;">
                    <router-link to="/indev" class="  nav_list_item__link" style=" font-family: Gilroy;font-size: 16px;font-style: normal;font-weight:600;line-height: normal; background-color:#1E1C24; width: auto; height: 15px;" >Статистика</router-link>
                </li>
                <li class="nav-item nav_list__item" style="margin-right: 70px;">
                    <router-link to="/matches" class="  nav_list_item__link" style=" font-family: Gilroy;font-size: 16px;font-style: normal;font-weight: 600;line-height: normal; background-color:#1E1C24; width: auto; height: 15px;">Матчи</router-link>
                </li>
            </ul>
        <div class="flexx">
            <div class="search-bar">
                <img src="../img/search.svg" alt="Search">
                <input type="text" placeholder="Поиск...">
            </div>
        <div class="button_container ">
            <a @click.prevent="openModal" href="" id="loginBtn" style="text-decoration: none;">
                <button class="button">Войти</button>
            </a>
            
                <router-link to="/rega"  class="button-register ms-2">Регистрация</router-link>
                
            
        </div>
        <div class="dropdown " >
            <button class="dropbtn">г. Калининград</button>
            <div class="dropdown-content">
                <a href="#" class="dropdown-item">Калининград</a>
                <a href="#" class="dropdown-item">Советск</a>
                <a href="#" class="dropdown-item">Светлогорск</a>
            </div>
        </div>
    </div>
    </div>
    </nav>
</header>
<div>
       
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <div class="madal_vxod__close">
        <img @click="closeModal" src="../img/Close_SM.svg" alt=""/>
      </div>

      <!-- Модальное окно для входа -->
      <div v-if="!forgotPasswordMode" class="modal-heder-cont">
        <div class="modal-header">Войти в аккаунт</div>
      </div>
      <div v-if="!forgotPasswordMode" class="main-div-all">
        <div class="main-text">Логин</div>
        <div class="main-div">
          <input v-model="form.email" type="email" class="main-div-input" required placeholder="Логин"/>
        </div>
      </div>
      <div v-if="!forgotPasswordMode" class="main-div-all">
        <div class="main-text">Пароль</div>
        <div class="main-div">
          <input v-model="form.password" type="password" class="main-div-input" required placeholder="Пароль"/>
        </div>
      </div>

      <div v-if="!forgotPasswordMode" class="checkBox-btn_cont">
        <div class="checkbox-label">
          <input type="checkbox" id="agree" class="checkbox-input"/>
          <label for="agree" class="checkbox-label">
            <div class="label_text">Запомнить меня</div>
          </label>
        </div>
        <a class="modal_a" @click="openForgotPassword">Забыли пароль?</a>
      </div>

      <button @click.prevent="handleLogin"  class="modal-btn">Войти</button>
      <div v-if="!forgotPasswordMode" class="modal-heder-cont">
        <div class="modal-footer">
          Нет аккаунта? 
          <router-link to="/rega" class="modal-footer-link">Зарегистрироваться</router-link>
        </div>
      </div>

      <!-- Модальное окно для восстановления пароля -->
      <div v-if="forgotPasswordMode">
        <div class="modal-header">Забыли пароль?</div>
        <div class="main-div-all" style="margin-top: 30px;">
              <div class="main-text">Почта</div>
              <div class="main-div" tipe="input">
                <input type="email" class="main-div-input" required placeholder="Почта" @mouseover="showValidationMessage" />
              </div>
              <div id="emailValidationMessage" v-show="isEmailValidationVisible" @click="showConfirmationBlock" style="color: #FD3D48; cursor: pointer;">
                Отправить код подтверждения
              </div>
            </div>

            <!-- Блок для подтверждения почты -->
            <div class="frame" v-show="isConfirmationBlockVisible">
              <div class="overlap-group-wrapper">
                <div class="overlap-group">
                  <div class="rectangle"></div>
                  <div class="text-wrapper">
                    <a href="" style="color: #49494a; cursor: pointer;">Отправить ещё раз 30с</a>
                  </div>
                  <p class="div">Введите код с вашей почты</p>
                  <div class="confirmation-inputs">
                    <div class="div-wrapper">
          <div class="text-wrapper-2">
            <input
              type="text"
              id="input1"
              maxlength="1"
              class="confirmation-input"
              style="text-align: center; font-size: 26px; color: #FFF; background-color: transparent; border: none; width: 60px; height: 60px;"
            />
          </div>
        </div>

        <div class="div-wrapper-2">
          <div class="text-wrapper-2">
            <input
              type="text"
              id="input2"
              maxlength="1"
              class="confirmation-input"
              style="text-align: center; font-size: 26px; color: #FFF; background-color: transparent; border: none; width: 60px; height: 60px;"
            />
          </div>
        </div>

        <div class="div-wrapper-3">
          <div class="text-wrapper-2">
            <input
              type="text"
              id="input3"
              maxlength="1"
              class="confirmation-input"
              style="text-align: center; font-size: 26px; color: #FFF; background-color: transparent; border: none; width: 60px; height: 60px;"
            />
          </div>
        </div>

        <div class="div-wrapper-4">
          <div class="text-wrapper-2">
            <input
              type="text"
              id="input4"
              maxlength="1"
              class="confirmation-input"
              style="text-align: center; font-size: 26px; color: #FFF; background-color: transparent; border: none; width: 60px; height: 60px;"
            />
          </div>
        </div>                  </div>
                  <div class="div-wrapper-5">
                    <div class="text-wrapper-3" @click="confirmEmail">Подтвердить</div>
                  </div>
                </div>
              </div>
            </div>

        <button class="modal-btn" @click="sendPasswordReset">Отправить</button>
      </div>
    </div>
  </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
          form: {
      email: '',
      password: '',
    },
    errors: null, // Переменная для ошибок
            showModal: false,
      forgotPasswordMode: false,
      isEmailValidationVisible: false,
      isConfirmationBlockVisible: false,
        };
    },
    methods: {
      
      handleLogin() {
    const email = this.form.email.trim();
    const password = this.form.password.trim();

    // Выводим значения в консоль для проверки
    console.log('Email:', email);
    console.log('Password:', password);

    // Проверка пустоты полей
    if (!email || !password) {
      alert('Заполните все поля');
      return;
    }

    // Отправка запроса на сервер
    axios.post('https://api.tiurinpro.ru/api/v1/auth/login', { email, password })
      .then(response => {
        console.log('Успешный вход:', response.data);
        alert('Вход выполнен успешно');
      })
      .catch(error => {
        console.error('Ошибка при входе:', error.response?.data || error);
        alert('Ошибка при входе');
      });
  },
  // Добавь метод для переключения между режимами
  toggleLoginMode() {
    this.form.loginMode = !this.form.loginMode;
    this.form.emailOnlyMode = !this.form.emailOnlyMode;
  },
        toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
        openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.forgotPasswordMode = false; // сброс режима при закрытии
    },
    openForgotPassword() {
      this.forgotPasswordMode = true;
    },
    sendPasswordReset() {
      // Логика для отправки восстановления пароля
      alert("Инструкция по восстановлению пароля отправлена на ваш email");
      this.closeModal();
    },
    showValidationMessage() {
      this.isEmailValidationVisible = true;
    },
    showConfirmationBlock() {
      this.isConfirmationBlockVisible = true;
    },
    confirmEmail() {
      const validationMessage = document.getElementById('emailValidationMessage');
      validationMessage.innerText = 'Почта подтверждена';
      validationMessage.style.color = '#00FF5A';
      this.isConfirmationBlockVisible = false;
    },
    handleConfirmationInput(index) {
      if (this.confirmationCode[index].length > 1) {
        this.confirmationCode[index] = this.confirmationCode[index].slice(0, 1);
      }
      if (index < this.confirmationCode.length - 1 && this.confirmationCode[index].length === 1) {
        this.$refs[`confirmationInput${index + 1}`]?.focus();
      }
    },
    checkForm() {
      if (Object.values(this.form).every((value) => value) && this.form.password === this.form.passwordConfirm) {
        alert('Регистрация прошла успешно');
      } else {
        alert('Заполните все поля и убедитесь, что пароли совпадают');
      }
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
  },
    
}


</script>
<style scoped>
/* modal */
/* Modal Overlay */
#emailValidationMessage span {
 color: #E14344;
 cursor: pointer;
 text-decoration: underline;
}
#emailValidationMessage span:hover {
color: #E14344;
   text-decoration: none;
 }

.main_div__input:valid ~ #emailValidationMessage {
 display: block;
}
.frame {
position: relative;
right: 30px;
}

.frame .overlap-group-wrapper {

width: 557px;
height: 271px;
}

.frame .overlap-group {
position: relative;
width: 410px;
height: 269px;
top: 2px;
left: 74px;
border-radius: 10px;
}

.frame .text-wrapper {
position: absolute;
top: 138px;
left: 105px;
color: var(--defaultregistration);
font-family: "Gilroy-SemiBold", Helvetica;
font-weight: 600;
font-size: 16px;
letter-spacing: 0;
line-height: normal;
}

.frame .rectangle {
/* margin-left: 30px; */
position: relative;
right: 400px;
margin-top: 30px;
position: absolute;
width: 410px;
height: 221px;
top: 0;
left: 0;
border-radius: 10px;
border: 2px solid;
border-color: #49494A;
}

.frame .div {
margin-top: 15px;
position: absolute;
top: 19px;
left: 86px;
color: #ffffff;
font-family: "Gilroy-SemiBold", Helvetica;
font-weight: 600;
font-size: 16px;
letter-spacing: 0;
line-height: normal;
}

.frame .div-wrapper {
flex-direction: column;
/* padding: 15px 22px; */
top: 60px;
left: 55px;
border: 2px solid;
border-color: #49494a;
display: inline-flex;
align-items: center;
justify-content: center;
gap: 10px;
position: absolute;
border-radius: 10px;
overflow: hidden;
}

.frame .text-wrapper-2 {
position: relative;
width: fit-content;
margin-top: -2px;
font-family: "Gilroy-SemiBold", Helvetica;
font-weight: 600;
color: #ffffff;
font-size: 24px;
letter-spacing: 0;
line-height: normal;
}

.frame .div-wrapper-2 {
flex-direction: column;
/* padding: 15px 22px; */
top: 60px;
left: 135px;
border: 2px solid;
border-color: #49494a;
display: inline-flex;
align-items: center;
justify-content: center;
gap: 10px;
position: absolute;
border-radius: 10px;
overflow: hidden;
}

.frame .div-wrapper-3 {
flex-direction: column;
/* padding: 15px 22px; */
top: 60px;
left: 215px;
border: 2px solid;
border-color: #49494a;
display: inline-flex;
align-items: center;
justify-content: center;
gap: 10px;
position: absolute;
border-radius: 10px;
overflow: hidden;
}

.frame .div-wrapper-4 {
flex-direction: column;
/* padding: 15px 22px; */
top: 60px;
left: 295px;
border: 2px solid;
border-color: #49494a;
display: inline-flex;
align-items: center;
justify-content: center;
gap: 10px;
position: absolute;
border-radius: 10px;
overflow: hidden;
}

.frame .div-wrapper-5 {
padding: 15px 93px;
top: 179px;
left: 55px;
background-color: #E14344;
display: inline-flex;
align-items: center;
justify-content: center;
gap: 10px;
position: absolute;
border-radius: 10px;
overflow: hidden;
}

.frame .text-wrapper-3 {
position: relative;
width: fit-content;
margin-top: -1px;
color: #ffffff;
font-family: "Gilroy-SemiBold", Helvetica;
font-weight: 600;
font-size: 16px;
letter-spacing: 0;
line-height: normal;
}
.modal_a{
    color: var(--default-text4, #787C87);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-decoration-line: underline;
position: relative;
bottom: 10px;
}
.checkbox-input:first-child{
    display: none;
}
.checkbox-input {
 display: none;
}
.checkBox-btn_cont{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;;
}
.modal-heder-cont{
    display: flex;
    align-items: center;
    justify-content: center;
}
.madal_vxod__close{
    position: absolute;
    top: 30px;
    left: 533px;
}
.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.checkbox-container {
 /* margin: 0 auto; */
 /* width: 100%; */
 margin-left: 176px;
 margin-top: 70px;
 display: flex;
 justify-content: center;
 align-items: center;
}

.label_text{
    color: #FFF;
    position: relative;
    left: 30px;
text-align: center;
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.checkbox-label {
 color: #FFF;

text-align: center;
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
 position: relative;
 /* padding-left: 30px; */
 cursor: pointer;
 color: white;
}

.checkbox-label::before {
 
 content: '';
 position: absolute;
 left: 0;
 top: -3px;
 width: 25px;
height: 25px;
flex-shrink: 0;
border-radius: 5px;
border: 1px solid #FFF;
}

.checkbox-label::after {
 content: url(../img/Kvadrat_ico.svg); /* Unicode character for checkmark */
 position: relative;

 top: -16px;
 left: -48px;
 transform: translate(-50%, -50%);
 font-size: 16px;
 color: white;
 opacity: 0;
}

.checkbox-input:checked + .checkbox-label::after {
 opacity: 1;
}
/* Modal Content */
.modal-content {
    /* display: flex; */
max-width: 580px;
height: auto;
padding: 30px;  
flex-direction: column;
/* align-items: center;  */
/* gap: 10px; */
flex-shrink: 0;
border-radius: 10px;
background: var(--default-main, #1E1C24);
}

.modal-header {
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.main-div-all {
    margin-top: 30px;
}

.main-text {
    margin-bottom: 10px;
    color: #EAECEC;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.main-div-input {
    margin-left: 30px;
    color: #fff;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: none;
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
}

.main-div-input::placeholder {
    transition: color 600ms ease;
}

.main-div:hover .main-div-input::placeholder,
.main-div:focus-within .main-div-input::placeholder {
    color: #E14344;
}

.main-div {
    display: flex;
    width: 100%;
    padding: 12px 0;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 2px solid #49494A;
    transition: border 600ms ease;
}

.main-div:hover,
.main-div:focus-within {
    border: 2px solid #E14344;
}

.modal-btn {
    margin-top: 20px;
    color: #EAECEC;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    display: flex;
    width: 100%;
    padding: 20px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #FD3D48;
    cursor: pointer;
}

.modal-footer {
    margin-top: 10px;
    color: #49494A;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-footer-link {
    color: #FD3D48;
}

/* modal */
*{
    transition: 0.3s ease;
    font-family: Gilroy;
    transform: 0.3s ease;
}
body {
    background-color: #151319;
    font-family: 'Gilroy', sans-serif;
  }


 /* шапка  */

  .nav{
    width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
.nav-link .nav_list_item__link {
    color: #EAECEC;
}
.nav_list_item__link {
    border: none;
    text-decoration: none;
    color: #EAECEC;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.bodyq {
    height: 2133px;
    z-index: 0;
}

.nav_list__item {
    display: flex;
    align-items: center;
    height: 83px;
    margin-right: 70px;
    transition: border-bottom 3s ease;
}
.nav_list__item:hover .nav_list_item__link {
    color: #E14344;
  
}
.nav_list__item:hover .nav_list_item__link:hover {
    color: #E14344;
}
.nav_list__item:hover .nav_list_item__link {
    color: #E14344;
  
    background-color: #2a2a2a; /* Цвет фона при наведении */
}
.nav_list__item:hover {
    color: #E14344; 
    border-bottom: 2px solid #E14344;
}

header {
    width: 100%;
    height: 85px;
    align-items: center;
    background-color: #1E1C24;
    backdrop-filter: blur(17.5px);
    padding-left: 70px;
    padding-right: 70px;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.search-bar {
    height: 37px;
    display: flex;
    width: 352px;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background: #151319;
    margin-left: 100px;
    margin-right: 40px;
}

.search-bar input {
    width: 80%;
    background: none;
    border: none;
    color: #EAECEC;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
}

.search-bar img {
    margin-top: 3px;
    margin-left: 20px;
    width: 20px;
    height: 20px;
}

.button {
    color: #EAECEC;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 100;
    position: relative;
    text-align: center;
    border-radius: 10px;
    background: #FD3D48;
    display: inline-flex;
    width: 85px;
    height: 37px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    outline: none;
    transition: background-color 0.3s ease;
}

.button:hover {
    background: #FF525C;
}
.navbar-toggler-iconn{
    height: 30px;
    width: 30px;
    background-image: url(../img/navbar_togler.svg);
}
.nav-link{
    color: #EAECEC;
}
.button-register {
    border: none;
    outline: none;
    height: 37px;
    list-style: none;
    color: #151319;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 100;
    position: relative;
    text-align: center;
    border-radius: 10px;
    background: #EAECEC;
    display: inline-flex;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 5px;
    transition: background-color 0.3s ease;
}

.button-register:hover {
    background: #B2B5BC;
}

.palka {
    background-color: #E14344;
    width: 120px;
    height: 2px;
    position: absolute;
    top: 135px;
    left: -126px;
    transition: transform 0.3s ease;
}

.nav_list_item__link:hover{
    color: #E14344;
    }
.nav_list{
    display: flex;
}
/* Стилизация полосы прокрутки */
::-webkit-scrollbar {
    width: 5px;
    background: #151319;
}

/* Стилизация ползунка */
::-webkit-scrollbar-thumb {
    border-radius: 2.5px;
    background: #EAECEC;
}
.head {
    width: 100%;
    background-color: #1E1C24;
    backdrop-filter: blur(17.5px);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
/* ... (остальные стили остаются без изменений) ... */


.head {
    
    width: 100%;
    background-color: #1E1C24;
    backdrop-filter: blur(17.5px);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-left: 70px;
    padding-right: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


#loginBtn{
    color: #EAECEC;
}
.flexx{
    display: flex ;
}
/* Общие стили для выпадающего меню */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: #1E1C24;
    color: #EAECEC;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #1E1C24;
    min-width: 160px;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.dropdown-content a {
    color: #EAECEC;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #EAECEC;
    color: #1E1C24;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #EAECEC;
    color: #1E1C24;
}
 /* Подывал */
footer{
    margin-top: 200px;
  }
  .footer__container {
    border-bottom: none;
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 20px 20px 0px 0px;
    border-top: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-main, #1E1C24);
    backdrop-filter: blur(17.5px);
    padding: 20px 0;
  }
  
  .footer__container__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 50px;
  }
  
  .footer__left,
  .footer__middle,
  .footer__right {
    flex: 1;
    margin-top: 80px;
  }
  .footer__middle{
    display: flex;
    margin-right: 148px;
  }
  .footer_container__ul {
    margin-right: 148px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-bottom: 20px;
  }
  
  .footer_container__ul li {
    color: #EAECEC;
    margin-bottom: 15px;
  }
  
  .footer_container_ul_li__first {
    font-weight: bold;
  }
  
  .footer_container_ul_li__a {
    text-decoration: none;
    color: #EAECEC;
  }
  
  .footer_container_ul_li__a:hover {
    color: #E14344;
  }
  .footer_container__ul:first-child{
    margin-right: 215px;
  }
  .footer_container__svetlogorsk{
    margin-bottom: 17px;
  }
  .footer_container__svg{
    margin-left: 285px;
  }
  .footer_text{
    display: flex;
    justify-content: center;
    align-items: end;
    height: 38px;
    border-top:2px solid #272A31 ;
    color: #888;
    margin-left: 250px;
    margin-right: 250px;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  }
  /* Медиа-запросы для адаптивности */
  @media (max-width: 768px) {
    .footer_text{
      margin-left: 0;
      margin-right: 0;
    }
    .footer_container__svg{
      margin-left:0;
    }
    .footer__container{
      margin-top: 540px;
    }
    .footer{
      margin-top: 540px;
    }
    .footer__middle{
      flex-direction: column;
    }
    .footer__container__content {
        flex-direction: column;
        align-items: center;
    }
  
    .footer__container__content > div {
        margin-bottom: 20px;
    }
  }
  @media (min-width: 768px) {
    
      .navbar-expand-md .navbar-collapse {
          justify-content: space-evenly;
          display: flex !important;
          flex-basis: auto;
      }
  }
  @media (min-width: 576px) { /* Стили для экранов шире 576px */
    .footer{
      margin-top: 540px;
    }
      header {
          min-height: 85px;
          height: auto; /* Автоматическая высота для адаптивности */
      }
      .search-bar {
          margin-left: -5px; /* Уменьшаем отступ для лучшей адаптивности */
      }
   
      .ms-3{
          margin-left:0rem!important ;
      }
  }
  @media (max-width:768px) {
    .footer{
      margin-top: 540px;
    }
      .ms-3{
          margin-left: 0rem !important;
      }
      .nav_list {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 20px;
          margin-left: 0;
      }
      .nav_list__item {
          margin-right: 0;
          margin-bottom: 10px;
      }
      .search-bar {
          margin-left: 0;
          margin-top: 20px;
      }
      .button_container {
          margin-top: 20px;
      }
      .ms-3{
          margin-left:0rem!important ;
      }
      .flexx{
           flex-direction: column;
      }
      header {
          height: auto; /* Автоматическая высота для адаптивности */
      }
  }
  @media (max-width: 575.98px) { /* Стили для экранов меньше 576px (мобильные устройства) */ 
       .flexx{
           flex-direction: column;
      }
        header {
          height: auto; /* Автоматическая высота для адаптивности */
      }
      header {
          flex-direction: column;
          align-items: flex-start;
      }
      .nav_list {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 20px;
          margin-left: 0;
      }
      .nav_list__item {
          margin-right: 0;
          margin-bottom: 10px;
      }
      .search-bar {
          margin-left: 0;
          margin-top: 20px;
      }
      .button_container {
          margin-top: 20px;
      }
  }
</style>