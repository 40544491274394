<script>
export default {
    data() {
        return {
            history:[ //Дублирую history из Matches, потом переделаешь если что
                {
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },
                {
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },
                {
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                }
            ],
        }
    }
}
</script>
<template>
<div class="consainer">
    <section class="section__history">
        <div class="history__content">
            <div class="history__head">
                <h3 class="history_head__text">
                    История матчей
                </h3>
            </div>
            <ul class="history__list">
                <li class="history_list__item" v-for="match in history" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <div class="item__left">
                        <p class="item__text item__date">
                            {{match.date}}
                        </p>
                        <p class="item__text item__name">
                            Матч №{{match.matchId}}
                        </p>
                    </div>
                    
                    <div class="item__center">
                        <p class="item__text item__your_team">
                            {{match.team}}
                        </p>
                        <p class="item_WorL">
                            {{match.result}}
                        </p>
                        <p class="item_check">
                            {{match.myChek}}:{{match.opCheck}}
                        </p>
                        <p class="item__text item_opposite__WorL" >
                            {{match.opResult}}
                        </p>
                        <p class="item__text item__opposite__team">
                            {{match.opTeam}}
                        </p>
                    </div>

                    <div class="item__right">
                        <p class="item_rigth__text">
                            Победа (+{{match.resultCheck}} mello)
                        </p>
                        <img src="../../assets/img/site/Arrow_right.svg" alt="" class="item_right__img" > 
                    </div>
                </li>
            </ul>
            <div class="history__footer"></div>
        </div>
    </section>
</div>
</template>
<style scoped>
/* Section History */
.section__history{
    border-radius: 57px 57px 10px 10px;
    border-bottom: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-main, #1E1C24);
    width: 100%;
    height: 700px;
    margin-bottom: 70px;
}

.history__content{
    padding-left: 70px;
    padding-right: 70px;
    position: relative;
}

/* Section History Header */ 

.history__head{
    border-bottom: 2px solid #272A31;
    margin-bottom: 20px;
}
.history_head__text{
    color: var(--default-main-text, #EAECEC);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding-top: 25px;
    margin-bottom: 23px;
}

/* Section History body */
.history__list{
    overflow: scroll;
    max-height: 520px;
    padding-right: 20px;
    overflow-x: hidden; /* Скрыть горизонтальный скролл */
}


::-webkit-scrollbar {
  width: 5px; /* Ширина скроллбара */
}

/* Основной трек скроллбара */
::-webkit-scrollbar-track {
  background: #151319; /* Цвет трека */
}

::-webkit-scrollbar-thumb {
  background: #EAECEC; /* Цвет полосы */
  border-radius: 2.5px; /* Скругление углов полосы */
}

::-webkit-scrollbar-thumb:hover {
  background: #FD3D48; /* Цвет полосы при наведении */
}
  

.history_list__item{
    background-color: #242229;
    display: flex;
    color:  white;
    border-radius: 7px;
    margin-bottom: 10px;
}


.item__text{
    color: var(--default-main-text, #EAECEC);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
}

/* Элементы расположенные слева */
.item__left{
    display: flex;
    margin-right: 360px;
    justify-content: space-between;
    
}

.item__date{
    border-radius: 7px;
    background: var(--default-bloki, #272A31);
    width: 140px;
    height: 45px;
    text-align: center;
    padding-top: 14px;
    margin-right: 30px;
    margin-bottom: 0px;
}

.item__name{
    padding-top: 15px;
    margin-bottom: 0px;
}

/* Элементы расположенные по центру */
.item__center{
    display: flex;
    justify-content: space-between;
    margin-right: 360px;
    margin-bottom: 0px;
}

.item__your_team{
    margin-right: 30px;
    padding-top: 15px;
}

.item_WorL{
    color: var(--default-text2, #00FF5A);
    font-size: 16px;
    background-color: #272A31;
    width: 45px;
    height: 45px;
    text-align: center;
    padding-top: 13px;
    border-radius: 7px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 10px;
    margin-bottom: 0px;
}

.item_check{
    color: var(--default-main-text, #EAECEC);
    padding: 15px 29px;
    background-color: #272A31;
    font-size: 16px;
    width: 87px;
    height: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 7px;
    margin-bottom: 0px;
}

.item_opposite__WorL{
    color: var(--default-main-logo, #FD3D48);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background-color: #272A31;
    width: 45px;
    height: 45px;
    text-align: center;
    padding-top: 13px;
    border-radius: 7px;
    margin-left: 10px;
    margin-right: 30px;
    margin-bottom: 0px;
}

.item__opposite__team{
    padding-top: 15px;
    margin-bottom: 0px;
}

/* Элементы расположенные cправа */
.item__right{
    display: flex;
    justify-content: space-between;
   padding-top: 15px;
}

.item_rigth__text{
    color: var(--default-text2, #00FF5A);   
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
}

.item_right__img{
    height: 30px;
    width: 12px;
    transform: translateX(20px) translateY(-5px);
}

.section__lobby{
    margin-top: 70px;
    margin-bottom: 70px;
}

.lobby__content{
    border-radius: 10px 10px 0px 0px;
    border-right: 2px solid var(--default-main-logo, #FD3D48);
    border-left: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-main, #1E1C24);
    height: 870px;
    padding-left: 70px;
    padding-right: 70px;
}


/* Header_lobby */
.lobby_content__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #272A31;
    height: 70px;
}
.lobby_content_header__game{
    display: flex;
    align-items: center;
    text-align: center;
}

.lobby_content_header__img{
    margin-right: 15px;
}

.lobby_content_header_game__name{
    color: var(--default-text4, #787C87);
    padding-top: 16px;
}

.lobby_content_header__gameMode{
    text-align: center;
    padding-top: 16px;
}

.lobby_content_header_gameMode__name{
    color: var(--default-text4, #787C87);
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transform: translateX(-23px);
}

.lobby_content_header__ping{
    display: flex;
}

.lobby_content_header_ping__meaning__select{
    background-image: url('../../assets/img/site/arrow.svg');
    background-color: #1E1C24;
    color: var(--default-text4, #787C87);
    border: none;
    outline: none;
    padding-right: 40px;
}
.lobby_content_header_ping__meaning__select:focus{
    box-shadow: none;
}

/* lobby body */
.btn{
    display: inline-flex;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.btn:focus{
    box-shadow: none;
}

.block_ButtonContainer{
    margin-top: 20px;
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
  }
  .blocka_button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 150px;
    height: 45px;
    flex-shrink: 0;
    color: var(--default-main-text, #EAECEC);
    font-family: Gilroy;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 7px;
    border: 2px solid var(--button-default, #272A31);
    transition: 0.3s ease;
    transform: 0.3s ease;
  }
  .blocka_button:focus{
    border: none;
  }
  .blocka_button:hover:not(.active) {
    border: #242229 solid 1px;
    align-items: center;
    justify-content: center;
   display: flex;
    background: var(--button-hover, #242229);
    border: none;
    transition: 0.3s ease;
    transform: 0.3s ease;
  }
  .blocka_button:active{
 
    background: var(--button-active, #FD3D48);
    transition: 0.3s ease;
    transform: 0.3s ease;
  }
  .active {
    background: var(--button-active, #FD3D48);
    border: none;
    transition: 0.3s ease;
    transform: 0.3s ease;
  }
  .btn_cont{
    width: 320px;

  }
  .command_block_main_body_btn__cont{
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn_main {
    z-index: 0;
    position: relative;
    left: -6px;
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
    border: none;
    background-color: #FD3D48; 
    background-image: url('../../assets/img/site/btn_bg.svg'); 
    background-size: contain; 
    background-position: center; 
    background-repeat: no-repeat;
    height: 60px;
    width: 280px;
    border-radius: 7px;
}
.btn_main:active, .btn_main:focus {
    background-color: #272A31;
}
.online{
    margin-top: 20px;
    color: var(--default-text2, #00FF5A);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
/* Cards_players */

.lobby__players__list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 120px;
    padding-right: 120px;
    margin-top: 20px
}


/* Other_player */
.first_player__card{
    border: 2px solid var(--button-default, #272A31);
    border-radius: 10px;
    transform: translateY(10px);
    width: 250px;
    margin-top: 75px;
    height: 368px;
    position: relative;
    flex-shrink: 0;
}

.first_player__card:hover{
    border: 2px solid var(--button-default, #FD3D48);
    border-radius: 10px;
}

.second_player__card{
    border: 2px solid var(--button-default, #272A31);
    border-radius: 10px;
    margin-top: 65px;
    position: relative;
    width: 250px;
    height: 408px;
    flex-shrink: 0;
    transition: all 0.2s ease-in-out;
}

.second_player__card:hover{
    border: 2px solid var(--button-default, #FD3D48);
    border-radius: 10px;
}

.btn_add_player{
    background-color: #1E1C24;
    color: #272A31;
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 45%;
    top: 45%;
}
.img_add_player:hover {
    fill: #FD3D48;
}
.borderr{
    margin-right: 20px;
    border-radius: 10px;
    border: 2px solid #272A31;

  }
  .borderr:hover{
    background: linear-gradient(180deg, #151319 0%, rgba(30, 28, 36, 0.00) 100%);


    border: 2px solid #EAECEC;
  }
  .players_cont{
    margin-bottom: 70px;
  }
  .players_cont_small{
    transition: 0.3s ease;
    transform: 0.3s ease;
    width: 250px;
height: 368px;
  }
  .players_cont_midle{
    transition: 0.3s ease;
    transform: 0.3s ease;
    width: 250px;
height: 408px;

  }
  .players_cont_xl{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    margin-right: 20px;
    width: 250px;
    height: 448px;
    border-radius: 10px;
border-bottom: 2px solid var(--default-main-logo, #FD3D48);
background: var(--default-bg, #151319);
  }
  .players_cont_xl_img{
   
    width: 150px;
height: 150px;
  }
  .players_cont_xl_content{
    margin-top: 25px;
    height: 225px;
  }
  .gamer_nick{
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .gammer_skore{
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
    width: 130px;
height: 30px;
flex-shrink: 0;
border-radius: 7px;
background: var(--default-main, #1E1C24);
  }
  .mello{
    margin-top: 16px;
    margin-left: 5px;
    color: var(--default-main-text, #EAECEC);
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .players_cont_xl_a{
    margin-bottom: 70px;
    color: var(--default-text5, #4A4D55);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .plas_crug{
 display: flex;
 align-items: center;
 justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 2px solid #272A31;
  }
  .plas_palkaVert{
     position: relative;
    left: 8px;
  height: 16px;
  width: 2px;
  border-radius: 2px;
  background: #272A31;  
  }
  .plas_palkaGor {
    position: relative;
    left: -1px;
    height: 2px;
    width: 16px;
    border-radius: 2px;
    background: #272A31;
}
.players_cont_small:hover .plas_crug {
    transition: 0.3s ease;
    transform: 0.3s ease;
    border-color: #EAECEC; 
}

.players_cont_small:hover .plas_palkaVert,
.players_cont_small:hover .plas_palkaGor {
    transition: 0.3s ease;
    transform: 0.3s ease;
    background: #EAECEC; 
}
.players_cont_midle:hover .plas_crug {
    transition: 0.3s ease;
    transform: 0.3s ease;
    border-color: #EAECEC; 
}

.players_cont_midle:hover .plas_palkaVert,
.players_cont_midle:hover .plas_palkaGor {
    transition: 0.3s ease;
    transform: 0.3s ease;
    background: #EAECEC; 
}

.korona_cont{
    margin-bottom: 20px;
}
.btn_main {
    z-index: 0;
    position: relative;
    left: -6px;
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
    border: none;
    background-color: #FD3D48; 
    background-image: url('../../assets/img/site/btn_bg.svg'); 
    background-size: contain; 
    background-position: center; 
    background-repeat: no-repeat;
    height: 60px;
    width: 280px;
    border-radius: 7px;
}
.btn_main:active, .btn_main:focus {
    background-color: #272A31;
}
/* Owner_player */
.lobby__players__owner__img{
    margin-left: 110px;
    margin-bottom: 20px;
}

.owner_player__card{
    border-radius: 10px;
    border-bottom: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-bg, #151319);
    width: 250px;
    height: 448px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    padding-bottom: 70px;
    text-align: center;
}

.lobby__players__avatar{
    margin-bottom: 15px;
}

.lobby__players__name{
    color: var(--default-main-text, #EAECEC);
    font-size: 16px;
    margin-bottom: 15px;
}

.lobby__players__raitin{
    width: 130px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 7px;
    background: var(--default-main, #1E1C24);
    color: var(--default-main-text, #EAECEC);
    padding-top: 5px;
    margin-left: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 110px;
}

.lobby__players__link{
    color: var(--default-text5, #4A4D55);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


/* lobby btns */
.lobby_content__btns{
    margin-top: 70px;
    padding-left: 295px;
    position: relative;
}


.lobby_contetn_btn_start{
    background-image: url('../../assets/img/site/btn_bg.svg');
    width: 280px;
    height: 60px;
    background-repeat: no-repeat;
    transition: all 0.2s ease-in-out;
    color: #EAECEC;
}

.lobby_contetn_btn_start:hover{
    background-image: url('../../assets/img/site/btn_search__match_hover.svg');
    color: #FD3D48;
}

.lobby_contetn_btn_deflaut{
    color: var(--default-main-text, #EAECEC);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 2px solid #272A31;
    width: 280px;
}

.lobby_contetn_btn_deflaut:hover{
    color: #EAECEC;
    background-color: #272A31;
}
.lobby_contetn_btn_deflaut:active{
    background-color: #FD3D48;
}
.lobby_content__btns__centerBlock__text{
    position: absolute;
    left: 45%;
    top: 80px;
    color: var(--default-text2, #00FF5A);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* lobby_footer */

.lobby_content__footer{
    border-radius: 0px 0px 57px 57px;
    border-right: 2px solid var(--default-main-logo, #FD3D48);
    border-left: 2px solid var(--default-main-logo, #FD3D48);
    background: linear-gradient(180deg, #1E1C24 0%, #151319 100%);
    height: 130px;
    margin-bottom: 70px;
}

.lobby_content_footer__head{
    color: var(--default-text4, #787C87);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.lobby_content_footer__list{
    display: flex;
    align-items: center;
    justify-content: center;
}

.lobby_content_footer__item{
    text-align: center;
    margin-right: 20px;
    color: var(--default-text5, #4A4D55);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.lobby_content__btns__centerBlock__svg_left{
    position: relative;
    bottom: -0.5px;
    left: 7px;

}
.lobby_content__btns__centerBlock__svg_right{
    position: relative;
    top: 0.5px;
    right: 6px;
}
.marl{
    margin-right: -8px;
}
.marr{
    margin-left: -8px;
}
.kapitan1{
    position: relative;
    bottom: 21px;
}
.kapitan_corona{
    position: relative;
    bottom: 3px;
    width: 18px;
    height: 15px; 
    margin-right: 10px;
}
.kapitan2{
    display: flex;
    justify-content: end;
    position: relative;
    bottom: 21px;
}
.kapitan_corona2{
    position: relative;
    bottom: -4px;
    width: 18px;
    height: 15px; 
    margin-left: 10px;
}
  .custom_found_match {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Выше, чем другие элементы */
    width: 580px;
    height: 398px;
    border-radius: 10px;
    border-bottom: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-main, #1E1C24);
    display: none; /* По умолчанию скрыто */
}

/* Стили для кнопки закрытия */
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #FFF;
    font-size: 24px;
    font-weight: bold;
}

/* Стили для подложки */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный цвет */
    z-index: 999; /* Выше, чем другие элементы, но ниже, чем модальное окно */
    display: none; /* По умолчанию скрыто */
}

.custom_found_match_head{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
    margin-left: 70px; 
    height: 70px;
    border-bottom: 1px solid #272A31;
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.custom_found_match_midle{
    margin-right: 70px;
    margin-left: 70px;
    border-bottom: 1px solid #272A31;
    height: 223px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.custom_found_match_midle_text{
    margin-top: 30px;
    margin-bottom: 30px;
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.custom_found_match_midle_text_timer{
    margin-bottom: 15px;
    color: var(--default-text5, #4A4D55);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.timer{
    bottom: 52px;
    position: relative;
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.prinat_cont{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
.prinat{
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
    border: none;
    display: flex;
width: 280px;
padding: 15px 30px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 7px;
background: var(--default-main-logo, #FD3D48);

}
.timer_img {
    animation: rotateImage 5s linear infinite; /* Здесь 5s - время одного оборота */
}

@keyframes rotateImage {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
.chat_cont{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .connekt_cont{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .connekt{
    display: flex;
    align-items: center;
    justify-content: space-between;display: flex;
    width: 580px;
    height: 36px;
    border-right: none;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 7px;
    border: 2px solid var(--button-default, #272A31);
    border-right: 1px;
  }
  .connekt_ip{
    margin-left: 20px;
    color: var(--default-main-text, #EAECEC);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .connekt_btn{
    border-right: 1px;
    color: var(--default-main-text, #EAECEC);
    background: transparent;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
    display: flex;
  width: 150px;
  height: 36px;
  padding: 10px 30px;
  align-items: center;
  gap: 10px;
  border-radius: 0px 7px 7px 0px;
  border: 2px solid var(--button-default, #272A31);
  }
  .connekt_btn:hover{
    background: var(--button-hover, #242229);
  }
  .connekt_btn_cont{
    display: flex;
  }
  .connekt_btn_img{
    cursor: pointer;
    margin-right: 18px;
  }
  .chat{
    margin-top: 20px;
    width: 1030px;
  height: 271px;
  flex-shrink: 0;
  border-radius: 7px;
  background: var(--default-bloki2, #242229);
  }
  .chat_massanges_cont{
    display: flex;
    flex-direction: column;
    align-items: end;
    overflow: auto;
    height: 211px;
    width: 100%;
  
  }
  .chat_inpit_cont{
    display: flex;
  width: 1030px;
  height: 60px;
  padding: 22.5px 20px;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 0px 0px 7px 7px;
  background: var(--default-bloki, #272A31);
  }
  .komu{
    margin-right: 5px;
    display: flex;
    color: var(--default-main-text, #EAECEC);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .Vsem{
    margin-left: 5px;
    color: var(--default-main-logo, #FD3D48);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .ToTeam{
    color: #3D8AFD;
    margin-left: 5px;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .chat_inpit{
    border: none;
    background: transparent;
    height: 60px;
    width: 921px;
    margin-left: 5px;
    color: var(--default-main-text, #EAECEC);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  ::-webkit-input-placeholder {
    /* Placeholder стили */
    color: var(--default-text5, #4A4D55);
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .chat_masage_cont{
    margin-bottom: 24px;
    display: flex;
  width: 1030px;
  height: 60px;
  padding: 22.5px 20px;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 0px 0px 7px 7px;
  background: var(--default-bloki, #272A31);
  }
  .chat_div{
    display: flex;
    align-items: center;
    margin-left: 5px;
    color: var(--default-main-text, #EAECEC);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  
  *{
    margin:  0px;
    padding: 0px;
    box-sizing: border-box;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../assets/fonts/gilroy-regular.woff2') format('woff2'),
       url('../../assets/fonts/gilroy-regular.woff') format('woff'),
       url('../../assets/fonts/gilroy-regular.ttf') format('truetype');

}

body{
    background-color: rgba(21, 19, 25, 1);
    font-family: 'Gilory', sans-serif;
}


a{
    text-decoration: none;
    color: white; 
}

ul{
    list-style: none;
    padding: 0px;
}

a:hover{
    color: #FD3D48;
}

.consainer{
    width: 1720px;
    margin: 0 auto;
}

.green{
    color: #00FF5A;
}

.red{
    color:#FD3D48;
}

/* Модальное окно истории матча */
#modal-bodyHistory{
    width: 1720px;
    height: 689px;
    border-radius: 10px;
    border-bottom: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-main, #1E1C24);
    position: absolute;
    left: -611px;
    z-index: 100;
    top: -400px;
    color: white;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
}


/* Hedaer modal */
.MoreDetails__head{
    display: flex;
    justify-content: space-between;
    text-align: center;
    color: var(--default-text4, #787C87);
    border-bottom: 2px solid #272A31;
    padding-bottom: 25px;
    padding-top: 10px;
    margin-bottom: 20px;
   
}

.MoreDetails__head__text{
    margin: 0px;
}


/* maps info */
.maps__list{
    display: flex;
    justify-content: space-between;
}

.team__item{
    width: 285px;
    height: 130px;
    border-radius: 7px;
    background: var(--default-bloki, #272A31);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.team__score{
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.maps__item{
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.maps__img{
    margin-bottom: 10px;
}

.maps__text{
    font-size: 16px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
}


/* Main info leftSide */
.left__PlayerItem{
    margin-right: 12px;
    padding-right: 30px;
    border-radius: 7px;
    display: flex;
    margin-bottom: 5px;
    background: linear-gradient(90deg, #242229 47.5%, #1E1C24 100%);
}

.left__PlayerAvatar{
    margin: 10px 20px;
    width: 45px;
    height: 45px;
}

.left__PlayerInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left__PlayerName{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3px;
}

.left__PlayerLink{
    width: 117px;
    color: var(--default-text4, #787C87);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.nameRow:not(:last-child){
    margin-right: 40px;
}

.nameRow{
    color: var(--default-text5, #4A4D55);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 20px;
    margin-bottom: 10px;
}

.main__left{
    display: flex;
}

.nameRow_div{
    margin-left: 300px;
}

.checkMyTeam{
    padding:14px;
    margin-top: 40px;
    padding-top: 5px;
    padding-left: 0px;
    margin-left: 20px;
    border-right:2px solid  #272A31;
    border-top:2px solid  #272A31;
}


.checkMyTeam_right{
    border-left:2px solid  #272A31;
    border-top:2px solid  #272A31;
    border-right:none;
    padding:14px;
    margin-top: 40px;
    margin-left: 0px;
    padding-top: 5px;
    padding-right: 0px;
}


.main__right{
    display: flex;
}

#right__checkMyTeam {
    padding: 14px;
    margin-right: 10px;
    padding-right: 0px;
    margin-top: 40px;
    padding-top: 5px;
    margin-left: -2px;
    border: none;
    border-left: 2px solid #272A31;
    border-top: 2px solid #272A31;
}


.modal__main{
    display: flex;
}


.checkMyTeam__item{
    display: flex;
    justify-content: space-between;
    border-radius: 7px;
    background: var(--default-bloki2, #242229);
    margin-bottom: 5px;
    align-items: center;
    padding-left: 20px;
}

.index_checkMyTeam{
    margin-right: 20px;
}

.infoTeam{
    border-radius: 7px;
    background-color: var(--default-bloki, #272A31);
    margin-left: 5px;
    width: 45px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center ;
}

.left__PlayerList{
    margin: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.checkMyTeam__list{
    margin: 0px;

    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}


.infoTeam_KD{
    width: 67px;
    height: 45px;
    border-radius: 7px;
    background: var(--default-bloki, #272A31);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-flex;
    justify-content: center;
    align-items: center ;
    margin-left: 5px;
}
.right__PlayerItem{
    margin-left: 85px;
    padding-left: 30px;
    border-radius: 7px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 5px;
    background: linear-gradient(270deg, #242229 47.5%, #1E1C24 100%);
}
.right__PlayerAvatar{
    margin: 10px 20px;
    width: 45px;
    height: 45px;
}

.right__PlayerInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.right__PlayerName{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3px;
}

.right__PlayerLink{
    width: 117px;
    color: var(--default-text4, #787C87);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.right__PlayerList{
    margin: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
/* Main info rightSide */
.team_name_cont{
    right: 195px;
    position: absolute;
    width: 1426px;
    display: flex;
    justify-content: space-evenly;
}
#replacement{
    width: 1720px;
    height: 689px;
    border-radius: 10px;
    border-bottom: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-main, #1E1C24);
    position: relative;
    left: -611px;
    z-index: 100;
    top: 0px;
    color: white;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
}
</style>