<template>
<profileHadder/>
</template>

<script>
import profileHadder from './componensProfile/profileblock_hadder.vue';
export default {
    components: { 
        profileHadder
        
      },
}
</script>

<style>

</style>