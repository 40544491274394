<template>
    <hadder/>
    <div class="container d-flex align-items-center justify-content-center flex-column" style="color: #fff; margin-top: 30px;font-family: Gilroy;font-size: 30px;font-style: normal;font-weight: 600;line-height: normal;" >
        В разработке
        <img src="../img/indev.svg" alt="">
    </div>
    <footer/>
</template>

<script>
import hadder from './hadder.vue';
import footer from './footer.vue';

export default {
 components: {
    hadder,
    footer
  }
}
</script>

<style>

</style>