import { createRouter, createWebHistory } from 'vue-router';
import Rega from '../components/rega.vue'; 
import main from '../components/main.vue'; 
import raiting from '../components/raiting.vue';
import Admin from '../components/Admin.vue';
import Matches from '../components/Matches.vue';
import indev from '@/components/indev.vue';
import profilestats from '../components/profile_stats.vue';


const routes = [
  {
    path: '/',
    redirect: '/main' 
  },
  {
    path: '/rega',
    name: 'Rega',
    component: Rega 
  },
  {
    path: '/main',
    name: 'main',
    component: main 
  },
  {
    path: '/indev',
    name: 'indev',
    component: indev 
  },
  {
    path: '/profilestats',
    name: 'profilestats',
    component: profilestats 
  },
  {
    path: '/raiting',
    name: 'raiting',
    component: raiting 
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
  },
  {
    path: '/matches',
    name: 'matches',
    component: Matches,
  },

  
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
