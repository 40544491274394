<script>
import CommandBlock2 from './componentsMatches/CommandBlock2.vue';
import HistoryWindow from './componentsMatches/HistoryWindow.vue';
import Header from './hadder.vue';
import Footer from './footer.vue';

export default {
    data() {
        return {
            ip:'191.55.255.22.11',
            gameName:'Counter-Strike',
            gameModeGlobal:'BO1',
            ping:698,
            status:'chill',
            servers:[{
                ping:68,
                name:"Frankfurt"
            },
            {
                ping:38,
                name:"Stockgolm"
            }],
            player:{
                name:'Maak',
                raiting:4230,
                id:1337,
                city_raititng:1,
                all_raititng:1,
                kills_in_game:18,
                K_D:1.32
            },
            cities:['г. Калининград','г. Москва','г. Санкт-Петербург'],
            gameMode:'5v5',
            playersCount:"4,999",
            numbers:[1,2,3,4,5,6,7,8,9,10,11,12,13],
            history:[
                {
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },
                {
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },
                {
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                },{
                    date:'13.03.2024',
                    matchId:2,
                    team: 'MyTeam',
                    myChek:8,
                    result:'W',
                    opTeam:'OpTeam',
                    opResult:'L',
                    opCheck:13,
                    resultCheck: 25,
                }
            ],
            curretMatch:{
                maps:[{
                    name:"Mirage",
                    Image: require('../assets/img/site/maps/MIRAGE.svg'),
                    B_or_P:'БАН'
                },{
                    name:"Vertigo",
                    Image: require('../assets/img/site/maps/VERTIGO.svg'),
                    B_or_P:'БАН'
                },{
                    name:"OVERPASS",
                    Image: require('../assets/img/site/maps/overpass.svg'),
                    B_or_P:'БАН'
                },{
                    name:"ANCIENT",
                    Image: require('../assets/img/site/maps/ancient.svg'),
                    B_or_P:'ПИК'
                },{
                    name:"AZTEC",
                    Image: require('../assets/img/site/maps/axtec.svg'),
                    B_or_P:'БАН'
                },{
                    name:"Inferno",
                    Image: require('../assets/img/site/maps/inferno.svg'),
                    B_or_P:'БАН'
                },{
                    name:"Nuke",
                    Image: require('../assets/img/site/maps/NUKE.svg'),
                    B_or_P:'БАН'
                }],
                myTeam:{
                    name:'MyTeam',
                    score:13,            
                    compound:[{
                        nickname:'Maaak',
                        id:1,
                        mello: 2300,
                        kills:25,
                        assists:4,
                        deaths:10
                    },{
                        nickname:'WXXF',
                        id:2,
                        mello: 2300,
                        kills:22,
                        assists:4,
                        deaths:16
                    },{
                        nickname:'CHOLL',
                        id:3,
                        mello: 2300,
                        kills:21,
                        assists:8,
                        deaths:27
                    },{
                        nickname:'FAN',
                        id:4,
                        mello: 2300,
                        kills:20,
                        assists:6,
                        deaths:28
                    },{
                        nickname:'KarniShon',
                        id:5,
                        mello: 2300,
                        kills:19,
                        assists:7,
                        deaths:20
                    },]},
                opTeam:{
                    name:'OpTeam',
                    score:8,
                    compound:[{
                            nickname:'KomBarD',
                            id:6,
                            mello: 2300,
                            kills:25,
                            assists:4,
                            deaths:10 
                        },{
                            nickname:'Selectl',
                            id:7,
                            mello: 2300,
                            kills:25,
                            assists:4,
                            deaths:10 
                        },{
                            nickname:'FanDeR',
                            id:8,
                            mello: 2300,
                            kills:25,
                            assists:4,
                            deaths:10 
                        },{
                            nickname:'DkRoman',
                            id:9,
                            mello: 2300,
                            kills:25,
                            assists:4,
                            deaths:10 
                        },{
                            nickname:'Gotik',
                            id:10,
                            mello: 2300,
                            kills:25,
                            assists:4,
                            deaths:10 
                        },
                    ],
                },
            },
            timerInterval: null,
            timerRunning: false, 
        }
    },
    methods: {
        getStyle(qwe) {
            console.log(qwe)
            let color = 'black';
            if (qwe == 'БАН') {  
              color = '#FD3D48';
            } else if (qwe == 'ПИК') {
              color = '#00FF5A';
            }
            
            // Возвращаем объект стилей
            return {
              color: color
            };
          },
    
        changeText(option) {
            // Отменяем активное состояние у всех кнопок
            var buttons = document.getElementsByClassName("blocka_button");
            for (var i = 0; i < buttons.length; i++) {
            buttons[i].classList.remove("active");
            }
        
            // Делаем кнопку, на которую кликнули, активной
            var clickedBtn = document.getElementById(option + "Btn");
            clickedBtn.classList.add("active");
        },

        startOrSkipTimer() {
            if (!this.timerRunning) {
                this.startTimer();
            }
        },

        startTimer() {
            var btn = document.querySelector('.btn_main');
            var seconds = 0;
            this.timerRunning = true;
            
            let maincontext = this;
            
            this.timerInterval = setInterval(function() {
                seconds++;
                btn.innerText = maincontext.formatTime(seconds);
                if (seconds >= 5) {
                    clearInterval(maincontext.timerInterval);
                    this.timerRunning = false;
                    btn.innerText = 'Поиск матча';
                    maincontext.openModal('customFoundModal'); // Открывает новое модальное окно после завершения таймера
                }
            }, 1000);
        },

        formatTime(seconds) {
            var minutes = Math.floor(seconds / 60);
            var remainingSeconds = seconds % 60;
                return (minutes < 10 ? '0' : '') + minutes + ':' + (remainingSeconds < 10 ? '0' : '') + remainingSeconds;
        },

        openModal(modalId) {
            var modal = document.getElementById(modalId);
            modal.style.display = 'block';
            this.startTimerr();
        },

        startTimerr() {
            var seconds = 20;
            var timerElement = document.querySelector('.timer');
            let maincontext = this;
            var timerInterval = setInterval(function() {
            seconds--;
            timerElement.innerText = seconds;
            if (seconds <= 0) {
                clearInterval(maincontext.timerInterval);
                maincontext.closeModall(); // Закрывает модальное окно после завершения таймера
            }
        }, 1000);
        },

        closeModall() {
        var modal = document.getElementById('customFoundModal');
        modal.style.display = 'none';

        var overlay = document.getElementById('overlay');
        overlay.style.display = 'none';
        },

        replacement() {
            var customFoundModal = document.getElementById('customFoundModal');
            var block1 = document.getElementById('command_block1');
            var block2 = document.getElementById('command_block2');
            console.log(block2);
            block1.style.display = 'none';
            block2.style.display = 'flex';
            customFoundModal.style.display = 'none';
            console.log(block2);
        },
    },
    components: {
        HistoryWindow,
        CommandBlock2,
        Header,
        Footer
    },
    mounted() {
        // коннект
        var img = document.getElementById('connekt_btn_img');
        var text = document.getElementById('connekt_ip');
        var originalIp = text.textContent; // Сохраняем изначальное значение IP-адреса
        var originalSrc = img.src;
        var hoverSrc = 'img/connect_hover.svg';
        var activeSrc = 'img/connect_active.svg';
        var isClicked = false;

        img.addEventListener('click', function() {
            if (!isClicked) {
                img.src = activeSrc;
                text.textContent = 'Скрыто';
                text.style.color = 'var(--default-text5, #4A4D55)';
                isClicked = true;
            } else {
                img.src = originalSrc;
                text.textContent = originalIp; // Возвращаем изначальное значение IP-адреса
                text.style.color = 'var(--default-main-text, #EAECEC)';
                isClicked = false;
            }
        });

        img.addEventListener('mouseover', function() {
            if (!isClicked) {
                img.src = hoverSrc;
            }
        });

        img.addEventListener('mouseout', function() {
            if (!isClicked) {
                img.src = originalSrc;
            }
        });

        // Обработчик события для кнопки "Копировать"
        document.getElementById('connekt_btn').addEventListener('click', function() {
            var ipAddress = originalIp; // Используем изначальное значение IP-адреса
            copyToClipboard(ipAddress); // Копируем его в буфер обмена
        });

        // Функция для копирования текста в буфер обмена
        function copyToClipboard(text) {
            var textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            alert('IP-адрес скопирован в буфер обмена: ' + text);
        }
        
        var commandBlock1 = document.getElementById('command_block1');
        var commandBlock2 = document.getElementById('command_block2');

        // Получаем ссылку на кнопку "ПРИНЯТЬ"
        var acceptButton = document.getElementById('prinat');

        // Добавляем обработчик события на кнопку "ПРИНЯТЬ"
        acceptButton.addEventListener('click', function() {
        // Скрываем первый блок и показываем второй блок
        commandBlock1.style.display = 'none';
        commandBlock2.style.display = 'block';
        });
        // var timerInterval; // Объявляем переменную для хранения интервала таймера
        // var timerRunning = false; // Флаг для отслеживания состояния таймера

        // function startOrSkipTimer() {
        // if (!timerRunning) {
        //     startTimer();
        // }
        // }

        // function startTimer() {
        // var btn = document.querySelector('.btn_main');
        // var seconds = 0;
        // timerRunning = true;
        // timerInterval = setInterval(function() {
        //     seconds++;
        //     btn.innerText = formatTime(seconds);
        //     if (seconds >= 5) {
        //         clearInterval(timerInterval);
        //         timerRunning = false;
        //         btn.innerText = 'Поиск матча';
        //         openModal('customFoundModal'); // Открывает новое модальное окно после завершения таймера
        //     }
        // }, 1000);
        // }

        // function formatTime(seconds) {
        // var minutes = Math.floor(seconds / 60);
        // var remainingSeconds = seconds % 60;
        // return (minutes < 10 ? '0' : '') + minutes + ':' + (remainingSeconds < 10 ? '0' : '') + remainingSeconds;
        // }

        // var modalId = 'customFoundModal';
        document.addEventListener('DOMContentLoaded', function() {
        // Делегирование события клика для крестика закрытия модального окна
        document.addEventListener('click', function(event) {
            if (event.target.classList.contains('close')) {
                closeModall();
            }
        });
        });

        // function openModal(modalId) {
        // var modal = document.getElementById(modalId);
        // modal.style.display = 'block';
        // startTimerr();
        // }
        // function startTimerr() {
        //     var seconds = 20;
        //     var timerElement = document.querySelector('.timer');
        //     var timerInterval = setInterval(function() {
        //     seconds--;
        //     timerElement.innerText = seconds;
        //     if (seconds <= 0) {
        //         clearInterval(timerInterval);
        //         closeModall(); // Закрывает модальное окно после завершения таймера
        //     }
        // }, 1000);
        // }

        // function closeModall() {
        // var modal = document.getElementById('customFoundModal');
        // modal.style.display = 'none';
        // }
        // function closeModall() {
        // var modal = document.getElementById('customFoundModal');
        // modal.style.display = 'none';

        // var overlay = document.getElementById('overlay');
        // overlay.style.display = 'none';
        // }


        function showModal() {
        var modal = document.getElementById('myModal');
        modal.style.display = 'block';
        }

        function closeModal() {
        var modal = document.getElementById('myModal');
        modal.style.display = 'none';
        }
        function replacement() {
            var customFoundModal = document.getElementById('customFoundModal');
            var block1 = document.getElementById('command_block1');
            var block2 = document.getElementById('command_block2');
            block1.style.display = 'none';
            block2.style.display = 'block'; 
            customFoundModal.style.display = 'none';
        }

        // Инициализация Bootstrap Collapse
        // document.addEventListener('DOMContentLoaded', function (){
        //     const navbarToggleBtn = document.getElementById('navbarToggleBtn');
        //     const navbarNav = document.getElementById('navbarNav');

        //     navbarToggleBtn.addEventListener('click', function () {
        //         navbarNav.classList.toggle('show');
        //     })});
    },
};

</script>
<template>

    <Header/>

<main>
        <section class="section__lobby" id="command_block1" style="display: block;">
            <div class="consainer">
                <div class="lobby__content">
                    <div class="lobby_content__header">
                        <div class="lobby_content_header__game">
                            <img src="../assets/img/site/CS_Icon.svg" alt="" class="lobby_content_header__img">
                            <p class="lobby_content_header_game__name">
                                {{ gameName }}
                            </p>
                        </div>

                        <div class="lobby_content_header__gameMode"> 
                            <p class="lobby_content_header_gameMode__name">
                                Режим {{ gameModeGlobal }}
                            </p>
                        </div>
                    
                        <div class="lobby_content_header__ping">
                            <img src="../assets/img/site/enternet_indicate.svg" alt="" class="lobby_content_header_ping__img">
                            <select class="form-select lobby_content_header_ping__meaning__select" aria-label="Default select example">
                                <option v-for="serv in servers" >{{serv.ping}}ms</option>
                            </select>
                        </div>

                    </div>

                    <div class="lobby_content__body">
                        <div class="block_ButtonContainer">
                            <div class="btn_cont d-flex justify-content-between">
                                <button id="playersBtn" class="blocka_button active" @click="changeText('players')">5v5 Рейтинг</button>
                                <button id="teamsBtn" class="blocka_button" @click="changeText('teams')">2v2 Рейтинг</button>
                            </div>
                      </div>
                      <div class="d-flex justify-content-center korona_cont" >
                        <img src="../assets/img/site/korona.svg" alt="" style="width: 30px;height: 25px;"> 
                    </div>
                        <div class="players_cont d-flex justify-content-center align-items-center" >
                            <a href="">
                                <!-- Внизу были атрибуты onmouseover="changeImage(this)" onmouseout="restoreImage(this)", которые не инициализированы -->
                            <div class="players_cont_small borderr d-flex justify-content-center align-items-center">
                               <div class="plas_crug">
                                <div class="plas_palkaVert"></div>
                                <din class="plas_palkaGor"></din>
                               </div>
                            </div>
                            </a>
                            <a href="">
                            <div class="players_cont_midle borderr d-flex justify-content-center align-items-center">
                                <div class="plas_crug">
                                    <div class="plas_palkaVert"></div>
                                    <din class="plas_palkaGor"></din>
                                   </div>
                            </div>
                            </a>
                           
                            <div class="players_cont_xl ">
                                <div class="players_cont_xl_content d-flex flex-column align-items-center justify-content-between">
                                <img src="../assets/img/site/Zaglushka_Avatara.svg" alt="" class="players_cont_xl_img">
                                <div class="gamer_nick">{{player.name}}</div>
                                <div class="gammer_skore d-flex align-items-center justify-content-center">{{player.raiting}}<p class="mello" style="margin-top: 0;">mello</p></div>
                            </div>
                            <a v-bind:href="'/profile/' + player.id" class="players_cont_xl_a">Открыть профиль</a>
                            </div>
                            <a href="">
                            <div class="players_cont_midle borderr d-flex justify-content-center align-items-center">
                                <div class="plas_crug">
                                    <div class="plas_palkaVert"></div>
                                    <din class="plas_palkaGor"></din>
                                   </div>
                            </div>
                            </a>
                            <a href="">
                            <div class="players_cont_small borderr d-flex justify-content-center align-items-center">
                                <div class="plas_crug">
                                    <div class="plas_palkaVert"></div>
                                    <din class="plas_palkaGor"></din>
                                   </div>
                            </div>
                            </a>
                        </div>

                        <div class="command_block_main_body_btn__cont">
                        <button style="width: 280px;height: 45px;" class="blocka_button " @click="changeText('players')">Пригласить игрока</button>
                        <img src="../assets/img/site/btn_palka_left.svg" alt="" style="position: relative; z-index: 2;">
                        <button class="btn_main" @click="startOrSkipTimer()">Поиск матча</button>
                        <img src="../assets/img/site/btn_palka_right.svg" alt="" style=" position: relative;left: -12px; z-index: 2;">
                        <button style="width: 280px;height: 45px;  position: relative;left: -12px;" class="blocka_button " @click="changeText('players')">Покинуть команду</button>
                    </div>
                    <div class="online d-flex align-items-center justify-content-center">Сейчас Online:{{playersCount}} </div>
                    </div>


                </div>
                <div class="lobby_content__footer">
                    <p class="lobby_content_footer__head">
                        Статистика за последние 20 матчей
                    </p>
                    <ul class="lobby_content_footer__list">
                        <li class="lobby_content_footer__item">
                            <p class="lobby_content_footer__item__text">Ваш mello: {{player.raiting}}</p>
                        </li>
                        <li class="lobby_content_footer__item">
                            <p class="lobby_content_footer__item__text">Место в РФ: {{player.city_raititng}}</p>
                        </li>
                        <li class="lobby_content_footer__item">
                            <p class="lobby_content_footer__item__text">Место по области: {{player.all_raititng}}</p>
                        </li>
                        <li class="lobby_content_footer__item">
                            <p class="lobby_content_footer__item__text">K/D: {{player.K_D}}</p>
                        </li>
                        <li class="lobby_content_footer__item">
                            <p class="lobby_content_footer__item__text">Киллов за матч: {{player.kills_in_game}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <CommandBlock2/>

        <HistoryWindow/>

    </main>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="d-flex justify-content-center align-items-center">
        <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="border: none; background-color: transparent;">
            <div class="modal-body" id="modal-bodyHistory">
                <div class="MoreDetails__head">
                    <p class="MoreDetails__head__text">
                        15.04.2024
                    </p>
                    <p class="MoreDetails__head__text green">
                        Победа (+25 mello)
                    </p>
                    <p class="MoreDetails__head__text">
                        Матч №111
                    </p>
                </div>
                
                <div class="modal__maps">
                    <ul class="maps__list">
                        <li class="team__item">
                            {{curretMatch.myTeam.name}}
                            <span class="team__score green">{{curretMatch.myTeam.score}}</span>
                        </li>
                        <li class="maps__item" v-for="cuurent in curretMatch.maps">
                            <img  v-bind:src="cuurent.Image"  alt="Карта" class="maps__img">
                            <span class="maps__text red" :style="getStyle(cuurent.B_or_P)">{{cuurent.B_or_P}}</span>
                        </li>
                        <li class="team__item">
                            {{curretMatch.opTeam.name}}
                            <span class="team__score red">{{curretMatch.opTeam.score}}</span>
                        </li>
                    </ul>
                </div>


                <div class="modal__main">

                    <ul class="left__PlayerList">
                        <div class="kapitan1">
                            <img src="../assets/img/site/korona.svg" alt="1" class="kapitan_corona" >
                            Капитан
                        </div>
                        <li class="left__PlayerItem" v-for="player in curretMatch.myTeam.compound">
                            <img src="../assets/img/site/Zaglushka_Avatara.svg" alt="Аватар" class="left__PlayerAvatar">
                            <div class="left__PlayerInfo">
                                <span class="left__PlayerName">{{player.nickname}}</span>
                                <a v-bind:href="'/profile/' + player.id" class="left__PlayerLink">Открыть профиль</a>
                            </div>
                        </li>
                    </ul>
                    <div class="team_name_cont">
                        <span class="team_name_cont__text">{{curretMatch.myTeam.name}}</span>
                        <span class="team_name_cont__text">{{curretMatch.opTeam.name}}</span>   
                    </div>
                    <div class="checkMyTeam">
                        <div class="nameRow_div">
                            <span class="nameRow">К</span>
                            <span class="nameRow">A</span>
                            <span class="nameRow">D</span>
                            <span class="nameRow">K/D</span>
                        </div>
                        <ul class="checkMyTeam__list">
                            <li class="checkMyTeam__item" v-for="player, index in curretMatch.opTeam.compound">
                                <div class="checkMyTeam__left">
                                    <span class="index_checkMyTeam">
                                        {{index + 1}}
                                    </span>
                                    <span class="nick_checkMyTeam">
                                        {{player.nickname}}
                                    </span>
                                </div>
                                <div class="checkMyTeam__rigt">
                                    <span class="infoTeam">
                                        {{player.kills}}
                                    </span>
                                    <span class="infoTeam">
                                        {{player.assists}}
                                    </span>
                                    <span class="infoTeam">
                                        {{player.deaths}}
                                    </span>
                                    <span class="infoTeam_KD">
                                        {{(player.kills / player.deaths).toFixed(2)}}
                                    </span>
                                </div>
                            </li>
                        </ul>
                   </div>
                  
                   <div class="checkMyTeam checkMyTeam_right">
                    <div class="nameRow_div">
                        <span class="nameRow">К</span>
                        <span class="nameRow">A</span>
                        <span class="nameRow">D</span>
                        <span class="nameRow">K/D</span>
                    </div>
                    <ul class="checkMyTeam__list">
                        <li class="checkMyTeam__item" v-for="player, index in curretMatch.opTeam.compound">
                            <div class="checkMyTeam__left">
                                <span class="index_checkMyTeam">
                                    {{index + 1}}
                                </span>
                                <span class="nick_checkMyTeam">
                                    {{player.nickname}}
                                </span>
                            </div>
                            <div class="checkMyTeam__rigt">
                                <span class="infoTeam">
                                    {{player.kills}}
                                </span>
                                <span class="infoTeam">
                                    {{player.assists}}
                                </span>
                                <span class="infoTeam">
                                    {{player.deaths}}
                                </span>
                                <span class="infoTeam_KD">
                                    {{(player.kills / player.deaths).toFixed(2)}}
                                </span>
                            </div>
                        </li>
                    </ul>
               </div>
               <ul class="right__PlayerList">
                <div class="kapitan2">
                    Капитан
                    <img src="../assets/img/site/korona.svg" alt="1" class="kapitan_corona2" >
                </div>
                <li class="right__PlayerItem" v-for="player in curretMatch.myTeam.compound">
                    <img src="../assets/img/site/Zaglushka_Avatara.svg" alt="Аватар" class="right__PlayerAvatar">
                    <div class="right__PlayerInfo">
                        <span class="right__PlayerName">{{player.nickname}}</span>
                        <a v-bind:href="'/profile/' + player.id" class="right__PlayerLink">Открыть профиль</a>
                    </div>
                </li>
            </ul>

                </div>
            </div>
        </div>
        </div>
        </div>
    </div>

    <!-- div overlay ничего в себя не включает, так должно быть? -->
    <div id="overlay" class="modal" style="display: none;" ></div>
    <div id="customFoundModal" class="custom_found_match" style="display: none;">
        <!-- Кнопка закрытия -->
        <span class="close" onclick="closeModall()">&times;</span>
        <!-- Содержимое модального окна -->
        <div class="content">
            <div class="custom_found_match_head">
                МАТЧ НАЙДЕН
            </div>
            <div class="custom_found_match_midle">
                <div class="custom_found_match_midle_text">Подтвердите свой матч 5v5 Рейтинг</div>
                <div class="custom_found_match_midle_text_timer">Истекает через:</div>
                <img src="../assets/img/site/timer.svg" alt="" class="timer_img ">
                <div class="timer">20</div>
            </div>

            <!--                 onclick="closeModall()"  -->
            <div class="prinat_cont"> <button class="prinat" id="prinat" @click="replacement()" >ПРИНЯТЬ</button></div>
        </div>
    </div>
    <!-- Здесь был лишний закрывающий div -->
    <Footer/>
</template>
<style scoped>
/* Section History */
.section__history{
    border-radius: 57px 57px 10px 10px;
    border-bottom: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-main, #1E1C24);
    width: 100%;
    height: 700px;
    margin-bottom: 70px;
}

.history__content{
    padding-left: 70px;
    padding-right: 70px;
    position: relative;
}

/* Section History Header */ 

.history__head{
    border-bottom: 2px solid #272A31;
    margin-bottom: 20px;
}
.history_head__text{
    color: var(--default-main-text, #EAECEC);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding-top: 25px;
    margin-bottom: 23px;
}

/* Section History body */
.history__list{
    overflow: scroll;
    max-height: 520px;
    padding-right: 20px;
    overflow-x: hidden; /* Скрыть горизонтальный скролл */
}


::-webkit-scrollbar {
  width: 5px; /* Ширина скроллбара */
}

/* Основной трек скроллбара */
::-webkit-scrollbar-track {
  background: #151319; /* Цвет трека */
}

::-webkit-scrollbar-thumb {
  background: #EAECEC; /* Цвет полосы */
  border-radius: 2.5px; /* Скругление углов полосы */
}

::-webkit-scrollbar-thumb:hover {
  background: #FD3D48; /* Цвет полосы при наведении */
}
  

.history_list__item{
    background-color: #242229;
    display: flex;
    color:  white;
    border-radius: 7px;
    margin-bottom: 10px;
}


.item__text{
    color: var(--default-main-text, #EAECEC);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
}

/* Элементы расположенные слева */
.item__left{
    display: flex;
    margin-right: 360px;
    justify-content: space-between;
    
}

.item__date{
    border-radius: 7px;
    background: var(--default-bloki, #272A31);
    width: 140px;
    height: 45px;
    text-align: center;
    padding-top: 14px;
    margin-right: 30px;
    margin-bottom: 0px;
}

.item__name{
    padding-top: 15px;
    margin-bottom: 0px;
}

/* Элементы расположенные по центру */
.item__center{
    display: flex;
    justify-content: space-between;
    margin-right: 360px;
    margin-bottom: 0px;
}

.item__your_team{
    margin-right: 30px;
    padding-top: 15px;
}

.item_WorL{
    color: var(--default-text2, #00FF5A);
    font-size: 16px;
    background-color: #272A31;
    width: 45px;
    height: 45px;
    text-align: center;
    padding-top: 13px;
    border-radius: 7px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 10px;
    margin-bottom: 0px;
}

.item_check{
    color: var(--default-main-text, #EAECEC);
    padding: 15px 29px;
    background-color: #272A31;
    font-size: 16px;
    width: 87px;
    height: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 7px;
    margin-bottom: 0px;
}

.item_opposite__WorL{
    color: var(--default-main-logo, #FD3D48);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background-color: #272A31;
    width: 45px;
    height: 45px;
    text-align: center;
    padding-top: 13px;
    border-radius: 7px;
    margin-left: 10px;
    margin-right: 30px;
    margin-bottom: 0px;
}

.item__opposite__team{
    padding-top: 15px;
    margin-bottom: 0px;
}

/* Элементы расположенные cправа */
.item__right{
    display: flex;
    justify-content: space-between;
   padding-top: 15px;
}

.item_rigth__text{
    color: var(--default-text2, #00FF5A);   
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
}

.item_right__img{
    height: 30px;
    width: 12px;
    transform: translateX(20px) translateY(-5px);
}

.section__lobby{
    margin-top: 70px;
    margin-bottom: 70px;
}

.lobby__content{
    border-radius: 10px 10px 0px 0px;
    border-right: 2px solid var(--default-main-logo, #FD3D48);
    border-left: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-main, #1E1C24);
    height: 870px;
    padding-left: 70px;
    padding-right: 70px;
}


/* Header_lobby */
.lobby_content__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #272A31;
    height: 70px;
}
.lobby_content_header__game{
    display: flex;
    align-items: center;
    text-align: center;
}

.lobby_content_header__img{
    margin-right: 15px;
}

.lobby_content_header_game__name{
    color: var(--default-text4, #787C87);
  
}

.lobby_content_header__gameMode{
    text-align: center;
    padding-top: 16px;
}

.lobby_content_header_gameMode__name{
    color: var(--default-text4, #787C87);
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transform: translateX(-23px);
}

.lobby_content_header__ping{
    display: flex;
}

.lobby_content_header_ping__meaning__select{
    margin-left: 5px;
    background-image: url('../assets/img/site/arrow.svg');
    background-color: #1E1C24;
    color: var(--default-text4, #787C87);
    border: none;
    outline: none;
    padding-right: 40px;
}
.lobby_content_header_ping__meaning__select:focus{
    box-shadow: none;
}

/* lobby body */
.btn{
    display: inline-flex;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.btn:focus{
    box-shadow: none;
}

.block_ButtonContainer{
    margin-top: 20px;
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
  }
  .blocka_button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 150px;
    height: 45px;
    flex-shrink: 0;
    color: var(--default-main-text, #EAECEC);
    font-family: Gilroy;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 7px;
    border: 2px solid var(--button-default, #272A31);
    transition: 0.3s ease;
    transform: 0.3s ease;
  }
  .blocka_button:focus{
    border: none;
  }
  .blocka_button:hover:not(.active) {
    border: #242229 solid 1px;
    align-items: center;
    justify-content: center;
   display: flex;
    background: var(--button-hover, #242229);
    border: none;
    transition: 0.3s ease;
    transform: 0.3s ease;
  }
  .blocka_button:active{
 
    background: var(--button-active, #FD3D48);
    transition: 0.3s ease;
    transform: 0.3s ease;
  }
  .active {
    background: var(--button-active, #FD3D48);
    border: none;
    transition: 0.3s ease;
    transform: 0.3s ease;
  }
  .btn_cont{
    width: 320px;

  }
  .command_block_main_body_btn__cont{
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn_main {
    z-index: 0;
    position: relative;
    left: -6px;
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
    border: none;
    background-color: #FD3D48; 
    background-image: url('../assets/img/site/btn_bg.svg'); 
    background-size: contain; 
    background-position: center; 
    background-repeat: no-repeat;
    height: 60px;
    width: 280px;
    border-radius: 7px;
}
.btn_main:active, .btn_main:focus {
    background-color: #272A31;
}
.online{
    margin-top: 20px;
    color: var(--default-text2, #00FF5A);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
/* Cards_players */

.lobby__players__list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 120px;
    padding-right: 120px;
    margin-top: 20px
}


/* Other_player */
.first_player__card{
    border: 2px solid var(--button-default, #272A31);
    border-radius: 10px;
    transform: translateY(10px);
    width: 250px;
    margin-top: 75px;
    height: 368px;
    position: relative;
    flex-shrink: 0;
}

.first_player__card:hover{
    border: 2px solid var(--button-default, #FD3D48);
    border-radius: 10px;
}

.second_player__card{
    border: 2px solid var(--button-default, #272A31);
    border-radius: 10px;
    margin-top: 65px;
    position: relative;
    width: 250px;
    height: 408px;
    flex-shrink: 0;
    transition: all 0.2s ease-in-out;
}

.second_player__card:hover{
    border: 2px solid var(--button-default, #FD3D48);
    border-radius: 10px;
}

.btn_add_player{
    background-color: #1E1C24;
    color: #272A31;
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 45%;
    top: 45%;
}
.img_add_player:hover {
    fill: #FD3D48;
}
.borderr{
    margin-right: 20px;
    border-radius: 10px;
    border: 2px solid #272A31;

  }
  .borderr:hover{
    background: linear-gradient(180deg, #151319 0%, rgba(30, 28, 36, 0.00) 100%);


    border: 2px solid #EAECEC;
  }
  .players_cont{
    margin-bottom: 70px;
  }
  .players_cont_small{
    transition: 0.3s ease;
    transform: 0.3s ease;
    width: 250px;
height: 368px;
  }
  .players_cont_midle{
    transition: 0.3s ease;
    transform: 0.3s ease;
    width: 250px;
height: 408px;

  }
  .players_cont_xl{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    margin-right: 20px;
    width: 250px;
    height: 448px;
    border-radius: 10px;
border-bottom: 2px solid var(--default-main-logo, #FD3D48);
background: var(--default-bg, #151319);
  }
  .players_cont_xl_img{
   
    width: 150px;
height: 150px;
  }
  .players_cont_xl_content{
    margin-top: 25px;
    height: 225px;
  }
  .gamer_nick{
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .gammer_skore{
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
    width: 130px;
height: 30px;
flex-shrink: 0;
border-radius: 7px;
background: var(--default-main, #1E1C24);
  }
  .mello{
    margin-top: 16px;
    margin-left: 5px;
    color: var(--default-main-text, #EAECEC);
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .players_cont_xl_a{
    margin-bottom: 70px;
    color: var(--default-text5, #4A4D55);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .plas_crug{
 display: flex;
 align-items: center;
 justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 2px solid #272A31;
  }
  .plas_palkaVert{
     position: relative;
    left: 8px;
  height: 16px;
  width: 2px;
  border-radius: 2px;
  background: #272A31;  
  }
  .plas_palkaGor {
    position: relative;
    left: -1px;
    height: 2px;
    width: 16px;
    border-radius: 2px;
    background: #272A31;
}
.players_cont_small:hover .plas_crug {
    transition: 0.3s ease;
    transform: 0.3s ease;
    border-color: #EAECEC; 
}

.players_cont_small:hover .plas_palkaVert,
.players_cont_small:hover .plas_palkaGor {
    transition: 0.3s ease;
    transform: 0.3s ease;
    background: #EAECEC; 
}
.players_cont_midle:hover .plas_crug {
    transition: 0.3s ease;
    transform: 0.3s ease;
    border-color: #EAECEC; 
}

.players_cont_midle:hover .plas_palkaVert,
.players_cont_midle:hover .plas_palkaGor {
    transition: 0.3s ease;
    transform: 0.3s ease;
    background: #EAECEC; 
}

.korona_cont{
    margin-bottom: 20px;
}
.btn_main {
    z-index: 0;
    position: relative;
    left: -6px;
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
    border: none;
    background-color: #FD3D48; 
    background-image: url('../assets/img/site/btn_bg.svg'); 
    background-size: contain; 
    background-position: center; 
    background-repeat: no-repeat;
    height: 60px;
    width: 280px;
    border-radius: 7px;
}
.btn_main:active, .btn_main:focus {
    background-color: #272A31;
}
/* Owner_player */
.lobby__players__owner__img{
    margin-left: 110px;
    margin-bottom: 20px;
}

.owner_player__card{
    border-radius: 10px;
    border-bottom: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-bg, #151319);
    width: 250px;
    height: 448px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    padding-bottom: 70px;
    text-align: center;
}

.lobby__players__avatar{
    margin-bottom: 15px;
}

.lobby__players__name{
    color: var(--default-main-text, #EAECEC);
    font-size: 16px;
    margin-bottom: 15px;
}

.lobby__players__raitin{
    width: 130px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 7px;
    background: var(--default-main, #1E1C24);
    color: var(--default-main-text, #EAECEC);
    padding-top: 5px;
    margin-left: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 110px;
}

.lobby__players__link{
    color: var(--default-text5, #4A4D55);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


/* lobby btns */
.lobby_content__btns{
    margin-top: 70px;
    padding-left: 295px;
    position: relative;
}


.lobby_contetn_btn_start{
    background-image: url('../assets/img/site/btn_bg.svg');
    width: 280px;
    height: 60px;
    background-repeat: no-repeat;
    transition: all 0.2s ease-in-out;
    color: #EAECEC;
}

.lobby_contetn_btn_start:hover{
    background-image: url('../assets/img/site/btn_search__match_hover.svg');
    color: #FD3D48;
}

.lobby_contetn_btn_deflaut{
    color: var(--default-main-text, #EAECEC);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 2px solid #272A31;
    width: 280px;
}

.lobby_contetn_btn_deflaut:hover{
    color: #EAECEC;
    background-color: #272A31;
}
.lobby_contetn_btn_deflaut:active{
    background-color: #FD3D48;
}
.lobby_content__btns__centerBlock__text{
    position: absolute;
    left: 45%;
    top: 80px;
    color: var(--default-text2, #00FF5A);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* lobby_footer */

.lobby_content__footer{
    border-radius: 0px 0px 57px 57px;
    border-right: 2px solid var(--default-main-logo, #FD3D48);
    border-left: 2px solid var(--default-main-logo, #FD3D48);
    background: linear-gradient(180deg, #1E1C24 0%, #151319 100%);
    height: 130px;
    margin-bottom: 70px;
}

.lobby_content_footer__head{
    color: var(--default-text4, #787C87);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.lobby_content_footer__list{
    display: flex;
    align-items: center;
    justify-content: center;
}

.lobby_content_footer__item{
    text-align: center;
    margin-right: 20px;
    color: var(--default-text5, #4A4D55);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.lobby_content__btns__centerBlock__svg_left{
    position: relative;
    bottom: -0.5px;
    left: 7px;

}
.lobby_content__btns__centerBlock__svg_right{
    position: relative;
    top: 0.5px;
    right: 6px;
}
.marl{
    margin-right: -8px;
}
.marr{
    margin-left: -8px;
}
.kapitan1{
    position: relative;
    bottom: 21px;
}
.kapitan_corona{
    position: relative;
    bottom: 3px;
    width: 18px;
    height: 15px; 
    margin-right: 10px;
}
.kapitan2{
    display: flex;
    justify-content: end;
    position: relative;
    bottom: 21px;
}
.kapitan_corona2{
    position: relative;
    bottom: -4px;
    width: 18px;
    height: 15px; 
    margin-left: 10px;
}
  .custom_found_match {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Выше, чем другие элементы */
    width: 580px;
    height: 398px;
    border-radius: 10px;
    border-bottom: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-main, #1E1C24);
    display: none; /* По умолчанию скрыто */
}

/* Стили для кнопки закрытия */
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #FFF;
    font-size: 24px;
    font-weight: bold;
}

/* Стили для подложки */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный цвет */
    z-index: 999; /* Выше, чем другие элементы, но ниже, чем модальное окно */
    display: none; /* По умолчанию скрыто */
}

.custom_found_match_head{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
    margin-left: 70px; 
    height: 70px;
    border-bottom: 1px solid #272A31;
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.custom_found_match_midle{
    margin-right: 70px;
    margin-left: 70px;
    border-bottom: 1px solid #272A31;
    height: 223px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.custom_found_match_midle_text{
    margin-top: 30px;
    margin-bottom: 30px;
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.custom_found_match_midle_text_timer{
    margin-bottom: 15px;
    color: var(--default-text5, #4A4D55);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.timer{
    bottom: 52px;
    position: relative;
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.prinat_cont{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
.prinat{
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
    border: none;
    display: flex;
width: 280px;
padding: 15px 30px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 7px;
background: var(--default-main-logo, #FD3D48);

}
.timer_img {
    animation: rotateImage 5s linear infinite; /* Здесь 5s - время одного оборота */
}

@keyframes rotateImage {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
.chat_cont{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .connekt_cont{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .connekt{
    display: flex;
    align-items: center;
    justify-content: space-between;display: flex;
    width: 580px;
    height: 36px;
    border-right: none;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 7px;
    border: 2px solid var(--button-default, #272A31);
    border-right: 1px;
  }
  .connekt_ip{
    margin-left: 20px;
    color: var(--default-main-text, #EAECEC);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .connekt_btn{
    border-right: 1px;
    color: var(--default-main-text, #EAECEC);
    background: transparent;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
    display: flex;
  width: 150px;
  height: 36px;
  padding: 10px 30px;
  align-items: center;
  gap: 10px;
  border-radius: 0px 7px 7px 0px;
  border: 2px solid var(--button-default, #272A31);
  }
  .connekt_btn:hover{
    background: var(--button-hover, #242229);
  }
  .connekt_btn_cont{
    display: flex;
  }
  .connekt_btn_img{
    cursor: pointer;
    margin-right: 18px;
  }
  .chat{
    margin-top: 20px;
    width: 1030px;
  height: 271px;
  flex-shrink: 0;
  border-radius: 7px;
  background: var(--default-bloki2, #242229);
  }
  .chat_massanges_cont{
    display: flex;
    flex-direction: column;
    align-items: end;
    overflow: auto;
    height: 211px;
    width: 100%;
  
  }
  .chat_inpit_cont{
    display: flex;
  width: 1030px;
  height: 60px;
  padding: 22.5px 20px;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 0px 0px 7px 7px;
  background: var(--default-bloki, #272A31);
  }
  .komu{
    margin-right: 5px;
    display: flex;
    color: var(--default-main-text, #EAECEC);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .Vsem{
    margin-left: 5px;
    color: var(--default-main-logo, #FD3D48);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .ToTeam{
    color: #3D8AFD;
    margin-left: 5px;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .chat_inpit{
    border: none;
    background: transparent;
    height: 60px;
    width: 921px;
    margin-left: 5px;
    color: var(--default-main-text, #EAECEC);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  ::-webkit-input-placeholder {
    /* Placeholder стили */
    color: var(--default-text5, #4A4D55);
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .chat_masage_cont{
    margin-bottom: 24px;
    display: flex;
  width: 1030px;
  height: 60px;
  padding: 22.5px 20px;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 0px 0px 7px 7px;
  background: var(--default-bloki, #272A31);
  }
  .chat_div{
    display: flex;
    align-items: center;
    margin-left: 5px;
    color: var(--default-main-text, #EAECEC);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  
  *{
    margin:  0px;
    padding: 0px;
    box-sizing: border-box;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/gilroy-regular.woff2') format('woff2'),
       url('../assets/fonts/gilroy-regular.woff') format('woff'),
       url('../assets/fonts/gilroy-regular.ttf') format('truetype');

}

body{
    background-color: rgba(21, 19, 25, 1);
    font-family: 'Gilory', sans-serif;
}


a{
    text-decoration: none;
    color: white; 
}

ul{
    list-style: none;
    padding: 0px;
}

a:hover{
    color: #FD3D48;
}

.consainer{
    width: 1720px;
    margin: 0 auto;
}

.green{
    color: #00FF5A;
}

.red{
    color:#FD3D48;
}

/* Модальное окно истории матча */
#modal-bodyHistory{
  
    width: 1720px;
    height: 689px;
    border-radius: 10px;
    border-bottom: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-main, #1E1C24);

    left: -611px;
    z-index: 100;
    top: 100px;
    color: white;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
}


/* Hedaer modal */
.MoreDetails__head{
    display: flex;
    justify-content: space-between;
    text-align: center;
    color: var(--default-text4, #787C87);
    border-bottom: 2px solid #272A31;
    padding-bottom: 25px;
    padding-top: 10px;
    margin-bottom: 20px;
   
}

.MoreDetails__head__text{
    margin: 0px;
}


/* maps info */
.maps__list{
    display: flex;
    justify-content: space-between;
}

.team__item{
    width: 285px;
    height: 130px;
    border-radius: 7px;
    background: var(--default-bloki, #272A31);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.team__score{
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.maps__item{
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.maps__img{
    margin-bottom: 10px;
}

.maps__text{
    font-size: 16px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
}


/* Main info leftSide */
.left__PlayerItem{
    margin-right: 12px;
    padding-right: 30px;
    border-radius: 7px;
    display: flex;
    margin-bottom: 5px;
    background: linear-gradient(90deg, #242229 47.5%, #1E1C24 100%);
}

.left__PlayerAvatar{
    margin: 10px 20px;
    width: 45px;
    height: 45px;
}

.left__PlayerInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left__PlayerName{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3px;
}

.left__PlayerLink{
    width: 117px;
    color: var(--default-text4, #787C87);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.nameRow:not(:last-child){
    margin-right: 40px;
}

.nameRow{
    color: var(--default-text5, #4A4D55);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 20px;
    margin-bottom: 10px;
}

.main__left{
    display: flex;
}

.nameRow_div{
    margin-left: 300px;
}

.checkMyTeam{
    padding:14px;
    margin-top: 40px;
    padding-top: 5px;
    padding-left: 0px;
    margin-left: 20px;
    border-right:2px solid  #272A31;
    border-top:2px solid  #272A31;
}


.checkMyTeam_right{
    border-left:2px solid  #272A31;
    border-top:2px solid  #272A31;
    border-right:none;
    padding:14px;
    margin-top: 40px;
    margin-left: 0px;
    padding-top: 5px;
    padding-right: 0px;
}


.main__right{
    display: flex;
}

#right__checkMyTeam {
     padding: 14px;
    margin-right: 10px;
    padding-right: 0px;
    margin-top: 40px;
    padding-top: 5px;
    margin-left: -2px;
    border: none;
    border-left: 2px solid #272A31;
    border-top: 2px solid #272A31;
}


.modal__main{
    display: flex;
    margin-top: 20px;
}


.checkMyTeam__item{
    display: flex;
    justify-content: space-between;
    border-radius: 7px;
    background: var(--default-bloki2, #242229);
    margin-bottom: 5px;
    align-items: center;
    padding-left: 20px;
}

.index_checkMyTeam{
    margin-right: 20px;
}

.infoTeam{
    border-radius: 7px;
    background-color: var(--default-bloki, #272A31);
    margin-left: 5px;
    width: 45px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center ;
}

.left__PlayerList{
    margin: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.checkMyTeam__list{
    margin: 0px;

    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}


.infoTeam_KD{
    width: 67px;
    height: 45px;
    border-radius: 7px;
    background: var(--default-bloki, #272A31);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-flex;
    justify-content: center;
    align-items: center ;
    margin-left: 5px;
}
.right__PlayerItem{
    margin-left: 85px;
    padding-left: 30px;
    border-radius: 7px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 5px;
    background: linear-gradient(270deg, #242229 47.5%, #1E1C24 100%);
}
.right__PlayerAvatar{
    margin: 10px 20px;
    width: 45px;
    height: 45px;
}

.right__PlayerInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.right__PlayerName{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3px;
}

.right__PlayerLink{
    width: 117px;
    color: var(--default-text4, #787C87);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.right__PlayerList{
    margin: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
/* Main info rightSide */
.team_name_cont{
    right: 195px;
    position: absolute;
    width: 1426px;
    display: flex;
    justify-content: space-evenly;
}
#replacement{
    width: 1720px;
    height: 689px;
    border-radius: 10px;
    border-bottom: 2px solid var(--default-main-logo, #FD3D48);
    background: var(--default-main, #1E1C24);
    position: relative;
    left: -611px;
    z-index: 100;
    top: 0px;
    color: white;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
}
</style>