<template>
  <profileHadder/>
  
  <div class="gammer_name">
    <p class="gammer_name_text">Мой профиль</p>
  </div>
  <main>
  <div class="prohile_hadder">  
    <div class="prohile_hadder_info">
        <div class="prohile_hadder_info_ava_inf_cont">
            <img src="../../img/avatar.svg" alt="">
            <div class="prohile_hadder_info_inf_block">
                <p class="prohile_hadder_info_inf_block_nick">
                    {{ gammer_name }}
                </p>
                <div class="prohile_hadder_info_inf_block_statsBlock">
                    <div class="prohile_hadder_info_inf_block_statsBlock_text">
                        <p class="prohile_hadder_info_inf_block_statsBlock_p">{{ mello }}</p> 
                        mello
                    </div>
                    <div class="prohile_hadder_info_inf_block_statsBlock_text">
                        <img src="../../img/rus.svg" alt="" class="prohile_hadder_info_inf_block_statsBlock_img">
                        <p class="prohile_hadder_info_inf_block_statsBlock_p">{{ ru_plase }}</p> 
                        место
                    </div>
                    <div class="prohile_hadder_info_inf_block_statsBlock_text">
                        <img src="../../img/kld.svg" alt="" class="prohile_hadder_info_inf_block_statsBlock_img">
                        <p class="prohile_hadder_info_inf_block_statsBlock_p">{{ kld_plase }}</p> 
                        место
                    </div>
                </div>
                <div class="button_container ">
            <a @click.prevent="openModal"  id="loginBtn" style="text-decoration: none;">
                <button class="button"><img src="../../img/karandash.svg" class="karandash" alt="">Настроить профиль</button>
            </a>
            
                <button   class="button-register ms-2">Пригласить в команду</button>
                <!-- <button class="more"><img src="../../img/more_prof.svg" alt=""></button> -->
                
            
        </div>
            </div>

                

        </div>
        <div class="achievements_container">
                    <div class="achievements_hadder">
                        <p class="achievements_hadder_text">Достижения </p><p class="achievements_hadder_text_link"> (Открыть все) </p>
                    </div>
                    <div class="elips_cont">
                        <div class="elips"></div>
                        <div class="elips"></div>
                        <div class="elips"></div>
                        <div class="elips"></div>
                    </div>
                </div>
    </div>
  </div>
  </main>
</template>

<script>
import profileHadder from './profileHadder.vue';
export default {
    
        data() {
            return {
                ru_plase:2,
                kld_plase:1,
                mello:4000,
                gammer_name:' KomBarD',
            }
        },
    
    components: { 
        profileHadder
        
      },
}
</script>

<style scoped>
.karandash{
    margin-right: 9px;
}
.elips{
    width: 67px;
height: 67px;
flex-shrink: 0;
background: var(--default-bloki, #272A31);
border-radius: 50%;
}
.elips_cont{
    display: flex;
    justify-content: space-between;
    width: 328px;
}
.achievements_hadder_text_link{
    color: var(--default-text5, #4A4D55);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 6px;
}
.achievements_hadder_text{
    color: var(--default-text4, #787C87);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.achievements_hadder{
    display: flex;

}
.achievements_container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.button_container{
    display: flex   ;
}
.more{
display: flex;
align-items: center;
justify-content: center;
width: 36px;
height: 36px;

border-radius: 7px;
background: var(--button-default, #272A31);
gap: 10px;
border: none;
}
.button {
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
    z-index: 100;
    position: relative;
    text-align: center;
    border-radius: 10px;
    border-radius: 7px;
    background: var(--button-default, #272A31);
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    outline: none;
    transition: background-color 0.3s ease;
}

.button:hover {
    background: #FF525C;
}
.navbar-toggler-iconn{
    height: 30px;
    width: 30px;
    background-image: url(../../img/navbar_togler.svg);
}
.nav-link{
    color: #EAECEC;
}
.button-register {
    margin-right: 15px;
    border: none;
    outline: none;
    height: 37px;
    list-style: none;
    color: var(--default-bg, #151319);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
    z-index: 100;
    position: relative;
    text-align: center;
    border-radius: 10px;
    background: #EAECEC;
    display: inline-flex;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 5px;
    transition: background-color 0.3s ease;
}

.button-register:hover {
    background: #B2B5BC;
}
.prohile_hadder_info_inf_block_statsBlock_img{
    flex-direction: column;
    margin-right: 5px;
    position: relative;
    top: -7px;
}
.prohile_hadder_info_inf_block_statsBlock_p{
    margin-right: 5.5px;
}
.prohile_hadder_info_inf_block_statsBlock_text{

    margin-right: 15px;
    display: flex;
    color: var(--default-text4, #787C87);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.prohile_hadder_info_inf_block_statsBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 280px;
}
.prohile_hadder_info_inf_block_nick{
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 10px;
}
.prohile_hadder_info_inf_block{
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 30px;
    padding-right:15px ;
    margin-left: 25px;
    width: 498px;
    height: 156px;
    border-radius: 7px;
    background: linear-gradient(90deg, rgba(21, 19, 25, 0.50) 0%, rgba(21, 19, 25, 0.10) 100%);
}
.prohile_hadder_info_ava_inf_cont{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.prohile_hadder_info{
    width: 693px;
    margin-right: 70px;
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
main{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.prohile_hadder{
    display: flex;
    justify-content: end;
    align-self: center;
    background-image: url(../../img/dg_prof.svg);
    width: 1780px;
    height: 440px;
}
.gammer_name{
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 70px;
}
 .gammer_name_text{
    color: var(--default-main-text, #EAECEC);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
 }
</style>